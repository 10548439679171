<template>
    <section id="mission-text">
        <p class="p-mission">
            Creativity is the tip of our spear. It’s what leads us on our mission, so we keep it sharp. Art is our religion. Plus, a couple more things… People are partners, not assets. And size is pointless if everything becomes impersonal. Making kickass work that respects our values and beliefs - that’s the whole point. While trying to craft work that is bold and beautiful, we work towards a society based on truth, understanding, and social accountability. Oh, and we seek to work with brands and companies that are working towards the same goals. Meanwhile, and along the way, we make time and space for experimentation and artistic innovation and growth in our storytelling, through passion projects of all sorts, big and small. So, getting back to the art thing… the tip of the spear, cuz that’s what’s on our mind first and foremost <span class="marked">- that shit is shaaaarp we tell you.</span>
        </p>
    </section>
</template>

<script>
    import {inject} from "vue";

    export default {
        name: "Mission",
        setup() {
            const hideImage = inject('hideImage')
            return {
                hideImage,
            }
        },
        beforeUpdate() {
            this.hideImage = true
        },
        created() {
            this.hideImage = true
        }
    }
</script>

<style scoped>
    #mission-text {
        margin-top: 120px;
        margin-left: 4%;
        margin-right: 4%;
    }
    .p-mission {
        font-family: 'replica', sans-serif !important;
        font-size: 40px;
    }
    .marked {
        box-shadow: 15px 0 0 0 #000, -5px 0 0 0 #000;
        background: #000;
        color: white;
        display: inline;
        padding: 3px 0 !important;
        position: relative;
    }
</style>