<template>
<section class="text-center not-found-container">
    <h1 class="text-white not-found-title">PÁGINA NÃO ENCONTRADA</h1>
</section>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>
    .not-found-container {
        padding: 100px;
    }
    .not-found-title {
        font-size: 3.6rem;
        box-shadow: 15px 0 0 0 #000, -5px 0 0 0 #000;
        background: #000;
        color: white;
        display: inline;
        padding: 5px 0 !important;
        position: relative;

    }
</style>