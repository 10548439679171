<template>
    <div
            v-for="(column, i) in videos"
            :key="column"
            class="image"
            :class="'column_'+i"
            @click="clickHandler"
            @mouseup="mouseMoveUpHandler"
            @mousemove="mouseMoveUpHandler"
    >
        <video-thumb
                v-for="(video,index) in column"
                :key="video.id"
                :filter="video.filter.location"
                :thumbnail="video.thumbnail"
                :title="video.title"
                :slug="video.slug"
                :right="index"
                :id="video.id"
                class="in-image out-selector"
                @mousedown="mouseDownHandler(video.id)"
        ></video-thumb>

    </div>
</template>

<script>
    import defaultRequest from "@/axiosConfig";
    import VideoThumb from "@/pages/video/Video";
    import {inject} from "vue";
    import $ from 'jquery'
    export default {
        name: 'VideoList',
        components: {VideoThumb},
        setup() {
            const insideVideo = inject('insideVideo', insideVideo)
            const hideImage = inject('hideImage', hideImage)
            return {
                insideVideo,
                hideImage
            }
        },
        beforeRouteLeave(_, _2, next) {
            if(this.holdActive) {
                this.holdActive = false
                next(false)
            } else {
                next()
            }
        },
        created() {
            this.hideImage = false
            defaultRequest
                .get('/video', {
                    responseType: "json",
                })
                .then((response) => {
                    response.data.map((element, index) => {
                        if (index % 2 === 0) {
                            const i = Math.floor(index/2);
                            const arr = []
                            arr.push(element)
                            if (index+1 in response.data) {
                                arr.push(response.data[index+1])
                            }
                            this.videos[i] = arr
                        }
                    })
                });
        },
        data: () => {
            return {
                videos: {},
                holdDelay: 500,
                holdStarter: null,
                holdActive: false
            }
        },
        beforeUpdate() {
            this.hideImage = false
        },

        methods: {
            clickHandler () {
                this.insideVideo = false
            },
            mouseDownHandler(id) {
                const seletedImage = $('#'+id)
                $('.out-selector').removeClass('zoom-out-left zoom-out-right zoom-out-right-sibling zoom-out-left-sibling zoom-out-undo')
                if (id%2 === 0) {
                    const idBrother = id-1
                    seletedImage.removeClass('out-selector').addClass('zoom-in-left')
                    $('#'+idBrother).removeClass('out-selector').addClass('zoom-in-right-sibling')
                } else {
                    const idBrother = id+1
                    seletedImage.removeClass('out-selector').addClass('zoom-in-right')
                    $('#'+idBrother).removeClass('out-selector').addClass('zoom-in-left-sibling')
                }
                const allImages = $('.out-selector')
                allImages.addClass('zoom-out')
                this.holdStarter = setTimeout(() => {
                    this.holdStarter = null;
                    this.holdActive = true;
                }, this.holdDelay);
            },
            mouseMoveUpHandler() {
                $('.zoom-in-left').removeClass('zoom-in-left').addClass('zoom-out-left').addClass('out-selector')
                $('.zoom-in-right').removeClass('zoom-in-right').addClass('zoom-out-right').addClass('out-selector')
                $('.zoom-in-right-sibling').removeClass('zoom-in-right-sibling').addClass('zoom-out-right-sibling').addClass('out-selector')
                $('.zoom-in-left-sibling').removeClass('zoom-in-left-sibling').addClass('zoom-out-left-sibling').addClass('out-selector')
                $('.zoom-out').removeClass('zoom-out').addClass('zoom-out-undo')
                if (this.holdStarter) {
                    // click
                    clearTimeout(this.holdStarter);
                }
            },
        }

    }
</script>

<style scoped>
    .image {
        display: flex;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        overflow: hidden;
    }
    .zoom-in-right {
        margin-top: 100px;
        transition: all .5s ease-in-out;
        transform: scale(1.2) translateX(40%);
    }
    .zoom-out-right {
        transition: all .5s ease-in-out;
        transform: scale(1);
    }
    .zoom-in-left {
        margin-top: 100px;
        transition: all .5s ease-in-out;
        transform: scale(1.2) translateX(-40%);
    }
    .zoom-out-left {
        transition: all .5s ease-in-out;
        transform: scale(1);
    }
    .zoom-in-right-sibling {
        transition: all .5s ease-in-out;
        transform: scale(0.5) translateX(-45%);
    }
    .zoom-out-right-siblingt {
        transition: all .5s ease-in-out;
        transform: scale(1) translateX(-40%);
    }
    .zoom-in-left-sibling {
        transition: all .5s ease-in-out;
        transform: scale(0.5) translateX(45%);
    }
    .zoom-out-left-siblingt {
        transition: all .5s ease-in-out;
        transform: scale(1) translateX(-40%);
    }
    .zoom-out {
        transition: all .5s ease-in-out;
        transform: scale(0.5);
    }
    .zoom-out-undo {
        transition: all .5s ease-in-out;
        transform: scale(1);
    }
    @media only screen and (max-width: 768px) {
        .image {
            display: block;
            justify-content: space-between;
            text-align: center;
            align-items: center;
            width: 95%;
            padding-left: 5%;
            margin-top: -25px;
        }
        .in-image {
            margin-bottom: 0;
        }
        .column_0 {
            margin-top: 30%;
        }
    }
</style>