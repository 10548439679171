<template>
  <header>
    <nav class="main-nav" id="nav" :class="{sticky:active}" @resize="resizeHandler">
      <ul class="col-md-12" :class="toggleNavClass">
        <li class="col-md-6 text-right" :style="'max-width: '+ innerWidthPer + 'px'" id="logo"><the-logo></the-logo></li>
        <li class="col-md-6" id="sub" style="text-align: justify" :style="['max-width: '+ innerWidthPer*0.82 + 'px','margin-left: '+ subInnerWidthPer*0.12 + 'px']"><the-subtitle></the-subtitle></li>
      </ul>
    </nav>
  </header>
</template>

<script>
import TheLogo from "@/components/layout/TheLogo";
import TheSubtitle from "@/components/layout/TheSubtitle";
import {provide, ref} from "vue";

export default {
  name: "TheHeader",
  components: {TheSubtitle, TheLogo},
  setup () {
    const navbarScrolled = ref(false)
    provide('navbarScrolled', navbarScrolled)
    return {
      navbarScrolled
    }
  },
  created() {
    this.resizeHandler(window.innerWidth)
  },
  data: () => {
    return {
      active: false,
      innerWidthPer: 0.48*1024,
      subInnerWidthPer: 0.48*1024,
      windowWidth: window.innerWidth
    }
  },
  watch: {
    windowWidth(newWidth) {
      this.resizeHandler(newWidth)
    }
  },
  methods: {
    toggleNavClass(){
      if(this.active === false){
        return 'nav'
      } else {
        return 'sticky-nav'
      }
    },
    resizeHandler(w) {
      if (w < 767) {
        this.innerWidthPer = w;
        this.subInnerWidthPer = w;
      } else {
        const per = window.innerWidth > 1600? 0.46: 0.48
        const perSub = window.innerWidth > 1600? 0.46+(w/6000): 0.48
        this.innerWidthPer = per * w
        this.subInnerWidthPer = perSub * w
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
  mounted(){
    window.document.onscroll = () => {
      let navBar = document.getElementById('nav');
      if(window.scrollY > navBar.offsetTop){
        this.active = true;
        this.navbarScrolled = true
      } else {
        this.active = false;
        this.navbarScrolled = false
      }
    },
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
}
</script>

<style scoped>
  header {
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 40px;
  }
  header nav {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  li {
    margin: 0 0.5rem;
  }
  .nav {
    transition: 100ms;
    padding: 25px;
  }
  .sticky-nav{
    transition: 100ms;
    padding: 20px;
    margin-left: 10%;
  }
  #nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    top: 0;
    z-index:1;
  }
  #nav.sticky{
    transition: 150ms;
    background-color: transparent;
    height: 150px;
  }
  .sticky #logo {
    -webkit-transform:scale(0.6);
    -moz-transform:scale(0.6);
    -ms-transform:scale(0.6);
    transform:scale(0.6);
    margin-left: -15%;
  }
  .sticky #sub {
    opacity: 1;
    margin-left: 21.2% !important;
    filter: invert(1) !important;
    transition : opacity .4s ease-in-out;
  }
  @media (max-width: 768px) {
    .main-nav {
      display: block;
    }
    .main-nav ul {
      display: block;
    }
    .sticky #logo {
      width: 100%;
      margin-top: 40%;
      margin-left: 0;
    }
    #nav.sticky{
      height: 100px;
    }
    header {
      margin-top: 60px;
    }
    .text-right {
      text-align: right !important;
    }
    #sub {
      text-align: justify !important;
      margin-left: 0 !important;
    }
    .sticky #logo {
      margin-top: -40px;
      margin-left: -15%;
    }
    .sticky #sub {
      display: none;
      margin-left: 0 !important;
    }
  }
</style>