<template>
  <div class="h-700"></div>
</template>

<style scoped>
  @media only screen and (max-width: 768px) {
    #tsparticles {
      margin-top: -1px;
    }
    .h-700 {
      height: 200px;
    }
  }
</style>

<script>
export default {
  name: "TheParticles"
}
</script>