<template>
  <router-link to="/" style="cursor: none">
    <div class="logo" @mouseenter="mouseEnterHandler" @mouseleave="mouseLeaveHandler"
    >
      <img src="@/assets/logo.png" :style="'display: ' + defaultImgDisplay">
      <img src="@/assets/logo.png" class="img-scrolled" :style="'display: ' + altImgDisplay">
    </div>
  </router-link>
</template>

<script>
import {inject} from "vue";

export default {
  name: "TheLogo",
  setup() {
    const navbarScrolled = inject('navbarScrolled')
    const insideLogo = inject('insideLogo')
    return {
      navbarScrolled,
      insideLogo,
    }
  },
  methods: {
    mouseEnterHandler() {
      this.insideLogo = true
    },
    mouseLeaveHandler() {
      this.insideLogo = false
    },
  },
  data: () => {
    return {
      defaultImgDisplay: 'block',
      altImgDisplay: 'none'
    }
  },
  watch: {
    navbarScrolled: function (value) {
      if (value) {
        this.defaultImgDisplay = 'none';
        this.altImgDisplay =  'block'
      } else {
        this.defaultImgDisplay = 'block';
        this.altImgDisplay =  'none'
      }
    }
  }
}
</script>

<style scoped>
  .logo {
    margin: 15px 20px;
    padding: 10px;
  }
  img {
    width: 100%;
    object-fit: contain;
    padding-left: 11px;
    padding-right: 5px;
  }
  .img-scrolled {
    margin-left: 27.3%;
  }
  @media (max-width: 768px) {
    img {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }
  }
</style>