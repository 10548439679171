<template>
  <h1
  class="marked animation grow"
  :class="classObject">
    {{title}}
  </h1>
</template>

<script>
export default {
  name: "video-title",
  props: ['title', 'right', 'mouseEnter', 'mouseLeave'],
  computed: {
    classObject() {
        return {
          'right': this.right,
          'left': !this.right,
          'fadeOutUp': this.mouseLeave,
          'fadeInBottom': this.mouseEnter
        }
    }
  }
}
</script>

<style scoped>
  .marked {
    box-shadow: 15px 0 0 0 #000, -5px 0 0 0 #000;
    background: #000;
    color: white;
    padding: 3px 0 !important;
    position: absolute;
    top:70%;
  }
  .left {
    left: 10%;
  }
  .right {
    right: 10%;
  }
  h1 {
    text-transform: capitalize;
    letter-spacing: 2px;
    opacity: 0;
  }
  .animation {
    animation-duration: 0s;
    animation-fill-mode: both;
  }
  .fadeInBottom {
    animation-name: fadeinBottom;
  }
  .fadeOutUp {
    animation-name: fadeOutUp;
  }
  @keyframes fadeinBottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1
    }
  }
  @keyframes fadeOutUp {
    from {
      opacity: 1;
      transform: translateY(-10%);
    }
    to {
      opacity: 0
    }
  }
  .grow {
    transition: all .2s ease-in-out;
  }
  .grow:hover {
    transform: scale(1.2);
  }
  @media only screen and (max-width: 768px) {
    h1 {
      opacity: 1;
      width: max-content;
    }
    .grow, .animation {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
    }
    .fadeInBottom {
      animation-name: none;
    }
    .fadeOutUp {
      animation-name: none;
    }
    .left {
      text-align: center;
      width: 45vw;
      left: unset;
      margin-left: 22vw;
    }
    .right {
      right: unset;
      width: 45vw;
      margin-left: 22vw;
    }
    .marked {
      top:60%;
    }

  }
</style>