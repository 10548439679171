<template>
    <section id="info-text">
        <p class="p-info h-500">
            LaPush is a creative studio, we colaborate with select forward-thinking agencies and brands to create all kinds of content, from script and from scratch, that challenge conceptions and represent positive human values. Lisbon-based, but working worldwide.
            <br>
            <span class="marked"> Reach us at  <a href="mailto:hello@lapush.studio">hello@lapush.studio</a></span>
        </p>
    </section>
</template>

<script>
    import {inject} from "vue";

    export default {
        name: "Info",
        setup() {
            const hideImage = inject('hideImage')
            return {
                hideImage,
            }
        },
        beforeUpdate() {
            this.hideImage = true
        },
        created() {
            this.hideImage = true
        }
    }
</script>

<style scoped>
    #info-text {
        margin-top: 120px;
        margin-left: 4%;
        margin-right: 4%;
    }
    .p-info {
        font-family: 'replica', sans-serif !important;
        font-size: 40px;
    }
    .marked {
        box-shadow: 15px 0 0 0 #000, -5px 0 0 0 #000;
        background: #000;
        color: white;
        display: inline;
        padding: 3px 0 !important;
        position: relative;
    }
    .marked > a {
        text-decoration: none;
        color: white;
    }
    .h-500 {
        height: 500px;
    }
</style>