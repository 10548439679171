<template>
  <div class="cursor" :class="classBlendObject">
    <div class="cursor_circle" :class="classObject">
      <svg height="64" width="64"><circle cx="32" cy="32" r="16"></circle>
      </svg>
    </div>
  </div>
  <div id="wrap"
      @mouseleave="mouseLeaveHandler"
      @mousemove="onMouseMoveHandler"
      style="z-index: 0"
  >
    <div class="grad" style="z-index: 1">
      <the-header></the-header>
      <router-view></router-view>
    </div>
    <the-particles :class="classHideImage" class="particles"></the-particles>
  </div>
  <footer class="footer">
    Designed by <a href="https://www.endybrasil.com/"><strong style="color: black">endybrasil</strong></a>
  </footer>
</template>

<script>
import TheHeader from "@/components/layout/TheHeader";
import { provide, ref } from 'vue'
import TheParticles from "@/components/layout/TheParticles";
import { gsap } from "gsap";

export default  {
  components: {
    TheParticles,
    TheHeader,
  },
  setup () {
    const deactivateMouse = ref(false)
    const insideLogo = ref(false)
    const insideSubtitle = ref(false)
    const insideVideo = ref(false)
    const insideIFrame = ref(false)
    const hideImage = ref(false)
    provide('deactivateMouse', deactivateMouse)
    provide('insideLogo', insideLogo)
    provide('insideSubtitle', insideSubtitle)
    provide('insideVideo', insideVideo)
    provide('insideIFrame', insideIFrame)
    provide('hideImage', hideImage)
    return {
      deactivateMouse,
      insideLogo,
      insideSubtitle,
      insideVideo,
      insideIFrame,
      hideImage
    }
  },
  data: () => {
    return {
      mousePos: {},
      cursor: {},
      hover: {},
    }
  },
  methods: {
    mouseMoveHandler(e) {
      this.mousePos = {
        x: e.pageX,
        y: e.pageY
      }
    },
    mouseLeaveHandler() {
      this.mousePos.x = -1;
      this.mousePos.y = -1;
    },
    onMouseMoveHandler(e) {
      gsap.to('.cursor', .4, {
        x: e.pageX - 16,
        y: e.clientY - 16
       })
    },

  },
  computed: {
    classObject() {
      return {
        'zoom_subtitle': this.insideSubtitle,
        'zoom_logo': this.insideLogo,
        'zoom_video': this.insideVideo,
        'iframe_hide': this.insideIFrame,
        'zoom_default': !this.insideSubtitle && !this.insideLogo && !this.insideVideo && !this.insideIFrame
      }
    },
    classBlendObject() {
      return {
        'mix-blend-saturaration': this.insideVideo,
        'mix-blend-difference': !this.insideVideo
      }
    },
    classHideImage() {
      return {
        'hide-image': this.hideImage
      }
    }
  },
  beforeRouteEnter() {
    this.showImage = true
  }
}
</script>

<style>
@font-face {
  font-family: 'druk-cond';
  src: local('druk-cond'),
  url('../src/assets/fonts/DrukCond-SuperItalic.otf') format('truetype');
}

@font-face {
  font-family: 'replica';
  src: local('replica'),
  url('../src/assets/fonts/Replica-Mono.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'druk-cond', sans-serif;
}

body {
  cursor: none;
}

body, p {
  font-family: 'replica', sans-serif;
}
.grad {
  background-image: linear-gradient(white, white);
  padding-bottom: 1px;
}
.footer {
  background-color: white;
  color: black;
  text-align: right;
  padding-right: 5%;
  padding-top: 25px;
  margin-top: -1%;
  font-size: 11px;
  padding-bottom: 2%;
}
.footer a {
  color: white;
  text-decoration: none;
}
.ball {
  pointer-events: none;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: gray;
  animation: implode 1s ease-in-out;
  animation-fill-mode: both;
  opacity: .5;
}
.cursor {
  position: fixed;
  z-index: 999999;
  pointer-events: none!important;
  border: black;
}
.cursor_circle {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: aliceblue;
  border: black;
}
.mix-blend-saturaration {
  mix-blend-mode: saturation !important;
}
.mix-blend-difference {
  mix-blend-mode: difference !important;
}
circle {
  fill: transparent;
  border: transparent;
}
.zoom_subtitle {
  transition: transform .2s; /* Animation */
  transform: scale(2.5);
}
.zoom_logo {
  transition: transform .2s; /* Animation */
  transform: scale(4);
}
.zoom_video {
  transition: transform .2s; /* Animation */
  transform: scale(5);
  mix-blend-mode: saturation !important;
}
.iframe_hide {
  display: none;
}
.particles {
  background-color: black;
  background-image: url('assets/ilustracao.png');
  -webkit-filter: invert(1);
  filter: invert(1);
  background-repeat: no-repeat;
  background-size: 55%;
  background-position: center;
  height: 1000px;
}
.zoom_default {
  transition: transform .2s; /* Animation */
  transform: scale(1);
}
.hide-image {
  display: none;
}
@keyframes implode {
  100% {transform: scale(0)}
}
@media only screen and (max-width: 768px) {
  .ball {
    opacity: 0;
  }
  .cursor {
    display: none;
  }
  
}

</style>

