import { createRouter, createWebHistory } from 'vue-router';
import VideoList from "./pages/video/VideoList";
import Info from "./pages/Info";
import Mission from "./pages/Mission";
import VideoDetails from "./pages/video/VideoDetails";
import NotFound from "./pages/NotFound";

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior () {
        return { left: 0, top: 0 }
    },
    routes: [
        { path: '/', component: VideoList },
        { path: '/info', component: Info },
        { path: '/mission', component: Mission },
        { path: '/video/:slug', name:'video', component: VideoDetails, props: true },
        { path: '/:notFound(.*)', component: NotFound },
    ],
});

export default router;